exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-contentful-post-sub-path-js": () => import("./../../../src/pages/{ContentfulPost.subPath}.js" /* webpackChunkName: "component---src-pages-contentful-post-sub-path-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-publication-biodiversity-js": () => import("./../../../src/pages/publication/biodiversity.js" /* webpackChunkName: "component---src-pages-publication-biodiversity-js" */),
  "component---src-pages-publication-businessandsustainability-js": () => import("./../../../src/pages/publication/businessandsustainability.js" /* webpackChunkName: "component---src-pages-publication-businessandsustainability-js" */),
  "component---src-pages-publication-climatechange-js": () => import("./../../../src/pages/publication/climatechange.js" /* webpackChunkName: "component---src-pages-publication-climatechange-js" */),
  "component---src-pages-publication-englishnews-js": () => import("./../../../src/pages/publication/englishnews.js" /* webpackChunkName: "component---src-pages-publication-englishnews-js" */),
  "component---src-pages-publication-greeninnovation-js": () => import("./../../../src/pages/publication/greeninnovation.js" /* webpackChunkName: "component---src-pages-publication-greeninnovation-js" */),
  "component---src-pages-publication-index-js": () => import("./../../../src/pages/publication/index.js" /* webpackChunkName: "component---src-pages-publication-index-js" */),
  "component---src-pages-publication-naturaldisaster-js": () => import("./../../../src/pages/publication/naturaldisaster.js" /* webpackChunkName: "component---src-pages-publication-naturaldisaster-js" */),
  "component---src-pages-publication-plasticandwastepollution-js": () => import("./../../../src/pages/publication/plasticandwastepollution.js" /* webpackChunkName: "component---src-pages-publication-plasticandwastepollution-js" */),
  "component---src-pages-publication-wildlife-js": () => import("./../../../src/pages/publication/wildlife.js" /* webpackChunkName: "component---src-pages-publication-wildlife-js" */)
}

